import React, { useEffect, useState } from "react";
import "./Dashboard.css";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

import { useDispatch, useSelector } from "react-redux";

import { connect } from "react-redux";
import { getTodos, updateAllTodos } from "../../../actions/todoActions";
import { getTodosStatusToday } from "../../../actions/todoTodayActions";
import { startChallenge } from "../../../actions/authActions";
import { getPoints } from "../../../actions/pointActions";
import { getAvailableTipsAndToolsByUser } from "../../../actions/tipsToolsActions";
import { updateAllRoutines, updateRoutine } from '../../../actions/routineActions';

//Avatar
import Avatar from "@material-ui/core/Avatar";

//divider
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Divider from "@material-ui/core/Divider";
import TodoScreen from "../Todo/JS/TodoScreen";
import SendIcon from "@material-ui/icons/Send";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import CancelIcon from '@material-ui/icons/Cancel';
import CachedIcon from '@material-ui/icons/Cached';
import { CheckCircle } from "@material-ui/icons";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

//moment
import moment from "moment";

//history
import { useHistory } from "react-router-dom";

//chart
import { Bar } from "react-chartjs-2";

//translation
import { useTranslation } from "react-i18next";
import { Typography } from "@material-ui/core";

import axios from "axios";

//change data component
import NotStartedYet from "../NotStartedYet";

function Dashboard({
  startChallenge,
  getTodosStatusToday,
  getTodos,
  getPoints,
  getAvailableTipsAndToolsByUser,
  updateAllRoutines,
  updateRoutine,
  updateAllTodos
}) {

  let history = useHistory();
  const auth = useSelector((state) => state.auth.user);
  const todos = useSelector((state) => state.todos.sort((a, b) => (a.createdAt < b.createdAt) ? 1 : -1));
  const points = useSelector((state) => state.points.points);
  const allTipsAndTools = useSelector((state) => state.tipsTools.allTipsAndTools);
  const routines = useSelector((state) => state.routines);
  const [userLevel, setUserLevel] = useState();
  const [weeklyTrimesterPoints, setWeeklyTrimesterPoints] = useState();

  const [test, setTest] = useState([]);
  const [testLastWeek, setTestLastWeek] = useState([]);

  const newDateToday = new Date().setHours(0, 0, 0, 0);
  const newDateTomorrow = moment(newDateToday).add(1, "days");

  const fullChallengeDays = moment(auth.endDayChallenge, "DD/MM/YYYY").diff(moment(auth.startDayChallenge, "DD/MM/YYYY"), "days");

  const currentChallengeDays = moment(auth.endDayChallenge, "DD/MM/YYYY").diff(moment(auth.startDayChallenge, "DD/MM/YYYY"), "days") - moment(auth.endDayChallenge, "DD/MM/YYYY").diff(moment(new Date()), "days");
  const dayProgression = (currentChallengeDays * 100) / fullChallengeDays;

  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
      backgroundColor: "var(--input-color)",
    },
  }));

  const [todo, setTodo] = useState({
    name: "",
    estimatedDuration: "",
    isCompleted: false,
    todoDate: new Date(),
  });

  const today = moment(new Date()).format("DD/MM/YYYY");
  const startChallengeUser = moment(auth.startDayChallenge, "DD/MM/YYYY").toDate();
  const startChallengeUserMoment = moment(startChallengeUser).format("DD/MM/YYYY");


  useEffect(async () => {
    getPoints(auth._id);
    //getTodos();
    updateAllTodos();
    getTodoNumberThisWeek();
    getTodoNumberLastWeek();
    //getAvailableTipsAndToolsByUser();
    updateAllRoutines();

    setUserLevel(await axios.get(`/api/auth/level/${auth._id}`));

    setWeeklyTrimesterPoints(await axios.get(`/api/point/pointsWeeklyTrimester/${auth._id}/${auth.currentTrimester}/${auth.currentWeek}`));
  }, []);

  useEffect(async () => {
    getTodoNumberThisWeek();
    getTodoNumberLastWeek();
    setUserLevel(await axios.get(`/api/auth/level/${auth._id}`));
    setWeeklyTrimesterPoints(await axios.get(`/api/point/pointsWeeklyTrimester/${auth._id}/${auth.currentTrimester}/${auth.currentWeek}`));
  }, [points, todos])

  const handleChange = (routine, event) => {
    const id = routine._id

    const updatedRoutine = {
      name: routine.name,
      isCompleted: event,
      comment: routine.comment,
    }

    updateRoutine(updatedRoutine, id);
  };

  function handleClickTodo() {
    history.push("/todo");
  }

  function handleClickProfile() {
    history.push("/my-profile");
  }

  const { t, i18n } = useTranslation("");

  function getTodoNumberThisWeek() {
    const currentDate = new Date();
    let monday = new Date(currentDate.setDate(currentDate.getDate() - currentDate.getDay() + 1)).setHours(0, 0, 0, 0);

    if (new Date() < monday) {
      monday = moment(new Date(monday)).subtract(7, 'days');
    }

    const tuesday = moment(new Date(monday)).add(1, "days");
    const wednesday = moment(new Date(tuesday)).add(1, "days");
    const thursday = moment(new Date(wednesday)).add(1, "days");
    const friday = moment(new Date(thursday)).add(1, "days");
    const saturday = moment(new Date(friday)).add(1, "days");
    const sunday = moment(new Date(saturday)).add(1, "days");

    let arrayWeekDay = [
      monday,
      tuesday,
      wednesday,
      thursday,
      friday,
      saturday,
      sunday,
    ];

    let arrayData = [];

    for (let wd in arrayWeekDay) {
      let count = 0;

      for (let pt in points.todos) {
        if (points.todos[pt].date == moment(new Date(arrayWeekDay[wd])).format("DD/MM/YYYY")) {
          //if (moment(new Date(points.todos[pt].date)).format("DD/MM/YYYY") == moment(new Date(arrayWeekDay[wd]).setHours(0, 0, 0, 0)).format("DD/MM/YYYY")) {
          count = count + 1;

          arrayData.splice(wd, 0, points.todos[pt].dailyNbOfTodo);
        }
      }

      if (count == 0) {
        arrayData[arrayData.length] = 0;
      }
    }

    setTest(arrayData);
  }

  function getTodoNumberLastWeek() {
    const currentDate = new Date();
    let monday = new Date(currentDate.setDate(currentDate.getDate() - currentDate.getDay() - 6)).setHours(0, 0, 0, 0);

    if (moment(new Date()).subtract(7, 'days') < monday) {
      monday = moment(new Date(monday)).subtract(7, 'days');
    }

    const tuesday = moment(new Date(monday)).add(1, "days");
    const wednesday = moment(new Date(tuesday)).add(1, "days");
    const thursday = moment(new Date(wednesday)).add(1, "days");
    const friday = moment(new Date(thursday)).add(1, "days");
    const saturday = moment(new Date(friday)).add(1, "days");
    const sunday = moment(new Date(saturday)).add(1, "days");

    let arrayWeekDay = [
      monday,
      tuesday,
      wednesday,
      thursday,
      friday,
      saturday,
      sunday,
    ];
    let arrayData = [];

    for (let wd in arrayWeekDay) {
      let count = 0;
      for (let pt in points.todos) {
        if (points.todos[pt].date == moment(new Date(arrayWeekDay[wd])).format("DD/MM/YYYY")) {
          count = count + 1;

          arrayData.splice(wd, 0, points.todos[pt].dailyNbOfTodo);
        }
      }

      if (count == 0) {
        arrayData[arrayData.length] = 0;
      }
    }

    setTestLastWeek(arrayData);
  }

  const state = {
    labels: ["Lun", "Mar", "Mer", "Jeu", "Ven", "Sam", "Dim"],
    datasets: [
      {
        label: "Nombre de taches par jour (cette semaine)",
        backgroundColor: "#584d91",
        data: test,
      },
      {
        label: "Nombre de taches par jour (semaine précédente)",
        backgroundColor: "#474fe5",
        data: testLastWeek,
      }
    ],
  };

  function truncate(string, n) {
    return string?.length > n ? string.substr(0, n - 1) + "..." : string;
  }

  const remainingDays = moment(auth.endDayChallenge, "DD/MM/YYYY").diff(moment(auth.startDayChallenge, "DD/MM/YYYY"), "days") - moment(auth.endDayChallenge, "DD/MM/YYYY").diff(moment(new Date()), "days");

  const classes = useStyles();
  return (
    <section className="section">
      <div className="dashboard__header container grid">
        <div className="dashboard__avatar">
          {/* <Avatar onClick={handleClickProfile}></Avatar> */}
          <Avatar></Avatar>
          <span>
            {t("dashboardScreen.hello")} {auth.name}
            <div className="dashboard__level">
              {userLevel && userLevel.data}
            </div>
          </span>
        </div>
        <div className="dashboard__points">
          <i className="uil uil-award-alt button__icon "></i>{" "}
          {points && points.totalPoints} pts
        </div>
      </div>

      {/* Check if challenge has already started or not */}
      {moment(startChallengeUserMoment) > moment(today) ? (
        <>
          <NotStartedYet />
        </>
      ) : (
        <>
          <div className="progression__container container ">
            <div className="progression__dates">
              <span>
                {/* {t("dashboardScreen.startDate")}:{" "}
            {moment(auth.startDayChallenge, "DD/MM/YYYY").format("DD MMMM YYYY")}{" "} */}
                {moment(auth.startDayChallenge, "DD/MM/YYYY").format("DD MMM YYYY")}{" "}
              </span>
              <span>
                {" "}
                Complété :{" "}
                {remainingDays}
                /
                {moment(auth.endDayChallenge, "DD/MM/YYYY").diff(
                  moment(auth.startDayChallenge, "DD/MM/YYYY"),
                  "days"
                )}{" "}
                j
              </span>
              <span>
                {/* {t("dashboardScreen.endDate")}:{" "}
            {moment(auth.endDayChallenge, "DD/MM/YYYY").format("DD MMMM YYYY")}{" "} */}
                {moment(auth.endDayChallenge, "DD/MM/YYYY").format("DD MMM YYYY")}{" "}
              </span>
            </div>

            <div className="progression__bar skills__bar">
              <span
                className=" progression__percentage skills__percentage "
                style={{ width: `${dayProgression}%` }}
              ></span>{" "}
            </div>
            <div className="progression__indicator">
              <span>1 {t("dashboardScreen.day")}</span>
              <span>
                {moment(auth.endDayChallenge, "DD/MM/YYYY").diff(
                  moment(auth.startDayChallenge, "DD/MM/YYYY"),
                  "days"
                )}{" "}
                {t("dashboardScreen.day")}
              </span>
            </div>
          </div>

          {/* <h1 className="section__title">Dashboard</h1>
      <span className="section__subtitle text-white">My Dashboard</span> */}

          <div className="test"></div>
          <div className="summary container">
            <h4>{t("dashboardScreen.progression")}</h4>
          </div>
          <div className="summary__rows grid">

          {weeklyTrimesterPoints && <>
            <div className="summary__content">
              <div>
                <h3 className="summary__title">{t("dashboardScreen.yearly")}</h3>
                <CircularProgressbar
                  value={points.yearlyTotalRealisedPercentage / 100}
                  maxValue={1}
                  text={`${points.yearlyTotalRealisedPercentage}%`}
                  styles={buildStyles({
                    strokeLinecap: "round",
                    pathColor: "var(--first-color)",
                    pathTransitionDuration: 3,
                    textColor: "var(--text-color)",
                  })}
                />
              </div>
            </div>
                
            <div className="summary__content">
              <div>
                <h3 className="summary__title">{t("dashboardScreen.trimester")}</h3>
                <CircularProgressbar
                  value={weeklyTrimesterPoints.data.pointsWeekly.tgCurrentPercentage / 100}
                  maxValue={1}
                  text={`${weeklyTrimesterPoints.data.pointsWeekly.tgCurrentPercentage}%`}
                  styles={buildStyles({
                    strokeLinecap: "round",
                    pathColor: "var(--first-color)",
                    pathTransitionDuration: 3,
                    textColor: "var(--text-color)",
                  })}
                />
              </div>
            </div>

            <div className="summary__content">
              <div>
                <h3 className="summary__title">{t("dashboardScreen.weekly")}</h3>
                <CircularProgressbar
                  value={weeklyTrimesterPoints.data.pointsWeekly.wgCurrentPercentage / 100}
                  maxValue={1}
                  text={`${weeklyTrimesterPoints.data.pointsWeekly.wgCurrentPercentage}%`}
                  styles={buildStyles({
                    strokeLinecap: "round",
                    pathColor: "var(--first-color)",
                    pathTransitionDuration: 3,
                    textColor: "var(--text-color)",
                  })}
                />
              </div>
            </div>
            </>
            }

          </div>
          <div className="dashboard__container container grid"></div>
          <div className="today__container container grid ">
            <h4> Routine </h4>
            <div className="listtoday">
              <List
                component="nav"
                className={classes.root}
                aria-label="mailbox folders"
              >
                {routines &&
                  routines.map((routine, i) => {
                    return (
                      <>
                        <ListItem button key={routine._id} onClick={handleClickTodo}>
                          <ListItemText primary={routine.name} />
                          <ListItemSecondaryAction>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={routine.isCompleted}
                                  onChange={(e) => {
                                    handleChange(routine, e.target.checked)
                                  }}
                                  color="primary"
                                />
                              }
                            />
                          </ListItemSecondaryAction>
                        </ListItem>
                        {routines.length === i + 1 ? (
                          <div></div>
                        ) : (
                          <div>
                            <Divider light />
                          </div>
                        )}
                      </>
                    );
                  })}
              </List>
            </div>
          </div>

          <div className="summary__rows container grid"></div>

          <div className="today__container container grid ">
            <h4> Taches pour aujourd'hui </h4>
            <div className="listtoday">
              <List
                component="nav"
                className={classes.root}
                aria-label="mailbox folders"
              >
                {todos &&
                  todos.map((td, i) => {
                    return (
                      <>
                        {/* <div>{moment(newDateToday).format("Do MMMM YYYY")}</div> */}
                        {/* {moment(td.todoDate).format("Do MMMM YYYY") >=
                      moment(newDateToday).format("Do MMMM YYYY") &&
                    moment(td.todoDate).format("Do MMMM YYYY") <
                      moment(newDateTomorrow).format("Do MMMM YYYY") ? ( */}
                        {td.todoDate == moment(newDateToday).format("DD/MM/YYYY") ? (
                          <>
                            {/* <TodoScreen todo={td} key={td._id} setTodo={setTodo} /> */}
                            <ListItem button key={td._id} onClick={handleClickTodo}>
                              {td.isCompleted ? (
                                <ListItemIcon>
                                  <CheckCircle className="isCompleted" />
                                </ListItemIcon>
                              ) : td.playing ? (
                                <ListItemIcon>
                                  <CachedIcon className="isRunning" />
                                </ListItemIcon>
                              ) : (
                                <ListItemIcon>
                                  <RadioButtonUncheckedIcon className="action" />
                                </ListItemIcon>
                              )}
                              <ListItemText primary={td.name} />
                              <ListItemSecondaryAction>
                                <NavigateNextIcon />
                              </ListItemSecondaryAction>
                            </ListItem>
                            {todos.length === i + 1 ? (
                              <div></div>
                            ) : (
                              <div>
                                <Divider light />
                              </div>
                            )}
                          </>
                        ) : (
                          <div></div>
                        )}
                      </>
                    );
                  })}
              </List>
            </div>

            {/* <div
          style={{
            width: "100",
            height: "300px",
          }}
        >
          <Bar
            data={state}
          />
        </div> */}
          </div>

          {/* <div className="activity__container container my__card">
        <h4> Activities </h4>
        <div className="activity__content">
          <div>
            <div className="activity__data">
              <span className="activity__text"> Activity 1</span>
              <i className="uil uil-angle-right-b activity__icon"></i>
            </div>
            <div className="activity__bar">
              <span className="activity__percentage"></span>
            </div>
          </div>

          <div>
            <div className="activity__data">
              <span className="activity__text"> Activity 1</span>
              <i className="uil uil-angle-right-b activity__icon"></i>
            </div>
            <div className="activity__bar">
              <span className="activity__percentage"></span>
            </div>
          </div>

          <div>
            <div className="activity__data">
              <span className="activity__text"> Activity 1</span>
              <i className="uil uil-angle-right-b activity__icon"></i>
            </div>
            <div className="activity__bar">
              <span className="activity__percentage"></span>
            </div>
          </div>
        </div>
      </div> */}
        </>
      )}
    </section>
  );
}
export default connect(null, {
  startChallenge,
  getTodosStatusToday,
  getTodos,
  getPoints,
  getAvailableTipsAndToolsByUser,
  updateAllRoutines,
  updateRoutine,
  updateAllTodos
})(Dashboard);
