import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route } from "react-router-dom";
import AddWeeklyGoalScreen from "./AddWeeklyGoalScreen";
import ListWeeklyGoalScreen from "./ListWeeklyGoalScreen";

import { connect } from 'react-redux';

//import { loadUser } from "../../../../../actions/authActions";

//modal
import Mymodal from "../../../../modal/Mymodal";
import MyAddbutton from "../../../../buttons/MyAddButton/MyAddbutton";
import ConfirmationModal from '../../../../modal/ConfirmationModal';
import IconButton from '@material-ui/core/IconButton';

const WeeklyGoalsScreen = ({ history, propsAdd = "weeklyADD", setOpenBackdrop, isAddComponent, setIsAddComponent, trimester }) => {
    const auth = useSelector((state) => state.auth.user);
    const [openConfirmation, setOpenConfirmation] = useState(false);
    const [idToDelete, setIdToDelete] = useState("");
    const weeklyDates = JSON.parse(localStorage.getItem("dates"));

    const [weeklyGoal, setWeeklyGoal] = useState({
        type: 3,
        estimatedNumberedGoal: "",
        name: "",
        note: "",
        percentageAchieved: 0,
        active: true,
        trimesterGoalID: "",
        trimester: trimester,
        week: "",
        deleteBtn: true,
        comment: "",
        weekToTodo: false,
    })

    //modal
    const [isUptate, setIsUpdate] = useState(false);

    return (
        <>
            <MyAddbutton propsAdd={propsAdd} />

            <ConfirmationModal
                openConfirmation={openConfirmation} setOpenConfirmation={setOpenConfirmation}
                confirmationTitle="Suppression d'un objectif hebdomadaire"
                confirmationText="En supprimant cet objectif hebdomadaire, vous allez également supprimer toutes les taches journalières liés à celui-ci.
                                    Etes-vous sur de vouloir supprimer cet objectif?"
                component="Weekly Goal"
                idToDelete={idToDelete}
                setOpenBackdrop={setOpenBackdrop}
            />

            <Mymodal
                className="goal--modal"
                modalTitle={`Objectif hebdomadaire - ${weeklyGoal.trimester.toUpperCase()}`}
                setIsAddComponent={setIsAddComponent}
                modalContent={
                    <>
                        <AddWeeklyGoalScreen weeklyGoal={weeklyGoal} setWeeklyGoal={setWeeklyGoal} isUptate={isUptate} setIsUpdate={setIsUpdate} locationComponent="goalScreen" setOpenBackdrop={setOpenBackdrop} isAddComponent={isAddComponent} />
                    </>
                }
            />

            <ListWeeklyGoalScreen
                setWeeklyGoal={setWeeklyGoal}
                isUptate={isUptate} setIsUpdate={setIsUpdate}
                setOpenBackdrop={setOpenBackdrop}
                setIsAddComponent={setIsAddComponent}
                setOpenConfirmation={setOpenConfirmation}
                setIdToDelete={setIdToDelete}
                trimester={trimester}
            />
        </>
    );
};

export default WeeklyGoalsScreen;
